<finad-header [hideCalculatorButton]="true" [showLogoutButton]="false">
    <ng-container right>
        <button matTooltip="{{ 'components.dashboard.buttons.infoTooltip' | translate }}" (click)="imprint()"
            mat-button>
            <span class="material-icons md-36">info</span>
        </button>
    </ng-container>
</finad-header>

<mat-card>
    <mat-card-header>
        <mat-card-title>{{ 'components.login.title' | translate}}</mat-card-title>
    </mat-card-header>
    <form>
        <ucba-input class="form-field" [control]="form.controls['email']"
            [labelText]="'components.login.email' | translate">
        </ucba-input>

        <ucba-input class="form-field" [control]="form.controls['password']" type="password"
            [labelText]="'components.login.password' | translate">
        </ucba-input>

        <button [disabled]="form.invalid" mat-raised-button color="primary" (click)="login()">
            {{ 'components.login.submitBtn' | translate }}
        </button>
    </form>
</mat-card>