import { Component } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpError } from '@curveball/http-errors';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AccountController } from '@ucba/sdk/controller';
import { ILoginResponse } from '@ucba/sdk/responses';
import { SdkPatchUser } from '@ucba/sdk/statemanagement/actions';
import { take } from 'rxjs/operators';

import { AuthenticationService } from '../../services';

/**
 * Komponente, mit der ein Login durchgeführt wird
 */
@Component({
    selector: 'finad-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    public form: FormGroup;

    /**
     * Konstruktor, erstellt Formular
     *
     * @param {UntypedFormBuilder} fb UntypedFormBuilder incjector
     * @param {Router} router Router injector
     * @param {TranslateService} translate TranslateService incjector
     * @param {NotificationService} notification NotificationService incjector
     * @param {AccountController} accountCtr AccountController incjector
     * @param {Store} store Store incjector
     * @param {WaiterService} waiter WaiterService injector
     * @param {AuthenticationService} authService AuthenticationService injector
     */
    public constructor(
        private fb: UntypedFormBuilder,
        private router: Router,
        private translate: TranslateService,
        private notification: NotificationService,
        private accountCtr: AccountController,
        private store: Store,
        private waiter: WaiterService,
        private authService: AuthenticationService,
    ) {
        this.form = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required]],
        });
    }

    /**
     * Führt den Login aus. Bei Erfolg wird das Token in den State gespeichert
     */
    public login(): void {
        this.waiter.show();
        this.accountCtr.login(this.form.value)
            .pipe(take(1))
            .subscribe({
                next: (result: ILoginResponse) => {
                    this.store.dispatch(new SdkPatchUser(result.user));
                    this.authService.appLogin(result);

                    this.waiter.hide();
                    this.router.navigateByUrl('/').catch(e => { throw e; });
                },
                error: (error: HttpError) => {
                    this.waiter.hide();
                    const errorMessage = error.httpStatus === 401 ? this.translate.instant('components.login.InvalidAuthentication') : error.message;

                    this.notification.alert(
                        this.translate.instant('components.login.invalidLogin'),
                        errorMessage,
                    );
                },
            });
    }

    /**
     * navigiert zum Impressum
     *
     * @returns {Promise<boolean>} navigations promise
     */
    public imprint(): Promise<boolean> {
        return this.router.navigateByUrl('/imprint');
    }
}
