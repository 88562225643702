import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { RateCalculationDialogComponent } from '@ucba/sdk/components';
import { IRateCalculationData } from '@ucba/sdk/interfaces';
import { HelperService } from '@ucba/sdk/services';
import { CustomerState, FinancingMapState, MasterDataState, UserState } from '@ucba/sdk/statemanagement/states';
import { MediaObserver } from 'ngx-flexible-layout';
import { Observable, Subject, takeUntil } from 'rxjs';

import { AuthenticationService } from '../../../../services';

/**
 * Komponente, welches einen generischen header bereitstellt mit logout und back button
 */
@Component({
    selector: 'finad-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

    @Input()
    public showBackButton?: boolean;

    @Input()
    public showLogoutButton?: boolean;

    @Input()
    public showHomeButton?: boolean;

    @Input()
    public hideCalculatorButton?: boolean;
    @Input() public toolTipMsg = '';
    // Reads the name of the state from the state class
    @Select(UserState.displayName)
    public displayName$: Observable<string> | undefined;

    @Select(CustomerState.currentName)
    public customerName$: Observable<string> | undefined;

    public isSmallScreen = false;

    /** Notifier wenn View verlassen wird */
    private viewLeft$ = new Subject<void>();

    /**
     * Standard Konstruktor
     * 
     * @param {Store} store Store injector
     * @param {Router} router Router injector
     * @param {Location} location Location injector
     * @param {MatDialog} dialog Dialog injector
     * @param {AuthenticationService} authService AuthenticationService injector
     * @param {MediaObserver} mediaObserver MediaObserver injector
     */
    public constructor(
        private store: Store,
        private router: Router,
        private location: Location,
        private dialog: MatDialog,
        private authService: AuthenticationService,
        private mediaObserver: MediaObserver,
    ) { }

    /**
     * Angular Hook beim verlassen
     */
    public ngOnDestroy(): void {
        this.viewLeft$.next();
    }

    /**
     * Angular Hook um Werte zu initialisieren
     */
    public ngOnInit(): void {
        this.mediaObserver.asObservable()
            .pipe(takeUntil(this.viewLeft$))
            .subscribe(mediaChange => {
                this.isSmallScreen = mediaChange.some(mc => mc.mqAlias === 'lt-md');
            });
    }

    /**
     * führt einen Logout aus
     */
    public logout(): void {
        this.authService.logout();
    }

    /**
     * navigiert zum Dashboard
     * 
     * @returns {Promise<boolean>} navigations promise
     */
    public home(): Promise<boolean> {
        return this.router.navigateByUrl('/');
    }

    /**
     * open the rate Calculator
     */
    public openCalculator(): void {
        const brutto = this.store.selectSnapshot(FinancingMapState.longFinancingRequirementBrutto);
        const fm = this.store.selectSnapshot(FinancingMapState.current);
        const referenceInterest = this.store.selectSnapshot(FinancingMapState.referenceInterest);
        const cfg = this.store.selectSnapshot(MasterDataState.determineFinancingConfiguration)();

        const dialogConfig: MatDialogConfig = {
            autoFocus: false,
            data: {
                amount: brutto > 10000 ? brutto : undefined,
                debitRate: HelperService.hasValue(fm?.requestedDebitRate) ? fm?.requestedDebitRate : referenceInterest > 0 ? referenceInterest : undefined,
                duration: !!fm && HelperService.hasValue(fm?.assumedDuration) ? fm.assumedDuration / 12 : undefined,
                calculationVersion: fm?.calculationVersion,
                bankAccountFee: cfg?.bankAccountFee,
                gracePeriod: fm?.gracePeriod,
            } as IRateCalculationData,
        }

        this.dialog.open(RateCalculationDialogComponent, dialogConfig);
    }

    /**
     * navigiert zur verherigen seite
     */
    public back(): void {
        this.location.back();
    }
}
