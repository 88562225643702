import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { GlobalSettings } from '@ucba/sdk'
import { Dispose } from '@ucba/sdk/statemanagement/actions';
import { take } from 'rxjs';

import { environment } from '../../environments/environment';

/**
 * Guard for check is user logged in,
 * - use canActivate: [AuthGuard] in routing module
 * 
 * @see https://codeburst.io/using-angular-route-guard-for-securing-routes-eabf5b86b4d1
 */
@Injectable({
    providedIn: 'root',
})
export class AuthGuard {

    /**
     * Standard Konstruktor
     * 
     * @param {Store} store Store injector
     * @param {NotificationService} notificationService NotificationService injector
     * @param {TranslateService} translate TranslateService injector
     */
    public constructor(
        private store: Store,
        private notificationService: NotificationService,
        private translate: TranslateService,
    ) { }

    /**
     * @override
     * @returns {boolean} kann route aktiviert werden
     */
    // eslint-disable-next-line class-methods-use-this
    public canActivate(): Promise<boolean> {
        if (GlobalSettings.assertAccessToken()) { // Token ist valide
            return Promise.resolve(true);
        }
        else if (!GlobalSettings.token) { // Token wurde noch nie gesetzt
            // navigate to external login
            window.open(`${environment.backendUrl}/Authorization/LoginWithExternalAccount`, '_self');
            return Promise.resolve(false);
        }
        else { // Token abgelaufen
            return new Promise<boolean>(resolve => {
                this.notificationService.alert(
                    this.translate.instant('global.invalidTokenTitle'),
                    this.translate.instant('global.invalidTokenText'),
                    false,
                )
                    .pipe(take(1))
                    .subscribe(() => {
                        this.store.dispatch(new Dispose());
                        window.open(`${environment.backendUrl}/Authorization/LoginWithExternalAccount`, '_self');
                        resolve(false);
                    })
            });
        }
    }
}
