import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@ntag-ef/notifications';
import { WaiterService } from '@ntag-ef/waiter';
import { AccountController, MasterDataController } from '@ucba/sdk/controller';
import { IListTuple } from '@ucba/sdk/interfaces';
import { IRegistrationResponse } from '@ucba/sdk/responses';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CompareValidation } from '../../validators';

/**
 * Komponente welche ein Formular für die Registrierung bereitstellt
 */
@Component({
    selector: 'finad-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
    public form: FormGroup | undefined;

    public branchList$: Observable<IListTuple<string>[]> | undefined;

    /**
     * Standard Konstruktor
     * 
     * @param {AccountController} accountController AccountController injector
     * @param {MasterDataController} masterDataController MasterDataController injector
     * @param {UntypedFormBuilder} fb UntypedFormBuilder injector
     * @param {Router} router Router injector
     * @param {NotificationService} notification NotificationService injector
     * @param {TranslateService} translate TranslateService injector
     * @param {NGXLogger} logger NGXLogger injector
     * @param {WaiterService} waiter WaiterService injector
     */
    public constructor(
        private accountController: AccountController,
        private masterDataController: MasterDataController,
        private fb: UntypedFormBuilder,
        private router: Router,
        private notification: NotificationService,
        private translate: TranslateService,
        private logger: NGXLogger,
        private waiter: WaiterService,
    ) { }

    /**
     * Angular Hook um Werte zu initialisieren
     */
    public ngOnInit(): void {

        this.branchList$ = this.masterDataController.getBranches()
            .pipe(map(branchArray => branchArray
            .map<IListTuple<string>>(({ name, id }) => ({
                label: name,
                value: id,
            }))));

        this.form = this.fb.group({
            firstname: [null, Validators.required],
            lastname: [null, Validators.required],
            email: [null, Validators.compose([Validators.required, Validators.email])],
            password: [null, Validators.required],
            branchid: this.fb.control([null, Validators.required], { updateOn: 'change'}),
        }, {
            updateOn: 'blur',
        });

        this.form.addControl(
            'confirmpassword',
            new FormControl(null, Validators.compose([
                Validators.required,
                CompareValidation.passwordCompare(this.form.controls['password']),
            ])),
        );
    }

    /**
     * startet die Registrierung am Backend
     */
    public registrate(): void {
        if (this.form?.valid) {
            this.waiter.show();
            this.accountController.register(this.form.value)
                .pipe(take(1)).subscribe(
                    async () => {
                        this.waiter.hide();
                        await this.router.navigateByUrl('login');
                    },
                    (error: HttpErrorResponse) => {
                        this.waiter.hide();
                        if (typeof error.error === 'string') {
                            this.logger.warn(new Error(error.error));

                            this.notification.alert(
                                this.translate.instant('components.registration.invalidRegistration'),
                                error.error,
                            );
                        }
                        else {
                            const e = error.error as IRegistrationResponse;
                            this.logger.warn(new Error(JSON.stringify(e)));

                            this.notification.alert(
                                this.translate.instant('components.registration.invalidRegistration'),
                                Array.isArray(e.errors) ? e.errors.join('<br>') : e.errors,
                            );
                        }
                    },
                );
        }
    }
}
