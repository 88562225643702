<waiter-component [isLoading]="!branchList$">
    <h2>{{'components.branchDialog.title' | translate}}</h2>
    <form *ngIf="!!form && branchList$">
        <ucba-select *ngIf="(branchList$ | async) as branches" class="form-field" [control]="form.controls['branchid']"
            [customLabel]="'components.branchDialog.branch' | translate" [array]="branches">
        </ucba-select>
        <button [disabled]="form.invalid" mat-raised-button color="primary" (click)="onSave()">
            {{ 'global.buttons.save' | translate }}
        </button>
    </form>
</waiter-component>