import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationService } from '@ntag-ef/notifications';
import { GlobalSettings, HelperService } from '@ucba/sdk';
import { UserState } from '@ucba/sdk/statemanagement/states';
import { DeviceDetectorService } from 'ngx-device-detector';

import { APPINFO } from '../../../environments/app-info';
import { environment } from '../../../environments/environment';

/**
 * Komponente zum darstellen des Impressums
 */
@Component({
    selector: 'finad-imprint',
    templateUrl: './imprint.component.html',
    styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent implements OnInit {
    public version: string | undefined;
    public buildDate: string | undefined;
    public backendUrl: string | undefined;
    public environment = environment;
    public browser = GlobalSettings.browser;

    public showMetaData = !environment.production || environment.backendUrl.includes('test');

    private errorCounter = 0;

    /**
     *Standard Konstuktor
     *
     * @param { Store } store Store injector
     * @param { Clipboard } clipboard Clipboard injector
     * @param { NotificationService } notification NotificationService injector
     * @param { TranslateService } translate TranslateService injector
     * @param { DeviceDetectorService } deviceService DeviceDetectorService injector
     */
    public constructor (
        private store: Store,
        private clipboard: Clipboard,
        private notification: NotificationService,
        private translate: TranslateService,
        private deviceService: DeviceDetectorService,
    ) { }

    /**
     * Angular Hook zum initialisieren
     */
    public ngOnInit(): void {
        this.version = APPINFO.version;
        this.buildDate = APPINFO.buildDate;
        this.backendUrl = environment.backendUrl;
    }

    /**
     * erzeugt testfehler um das Logging zu testen
     */
    public generateTestError(): void {
        if (GlobalSettings.debugMode) {
            this.errorCounter++;
            if (this.errorCounter === 5) {
                this.errorCounter = 0;

                setTimeout(() => {
                    throw new Error('TestError2');
                }, 1);

                throw new Error('TestError1');
            }
        }
    }

    /**
     * fügt die Versionsinformationen zum Clipboard hinzu
     */
    public copySystemInfo() {
        const userEmail = this.store.selectSnapshot(UserState.current)?.email;
        const infos = [
            'Anwendung: FinAdvisory',
            `Version: ${this.version} (${this.buildDate})`,
            `User: ${userEmail ?? 'nicht angemeldet'}`,
            `Gerätetyp: ${this.deviceService.deviceType}`, // 'desktop', 'mobile', 'tablet', 'unknown'
            `UserAgent: ${navigator.userAgent}`,
            `Browser: ${this.deviceService.browser} (${this.deviceService.browser_version})`,
            `Betriebssystem: ${this.deviceService.os} (${this.deviceService.os_version})`,
            `Gerätemodel: ${this.deviceService.device}`,
            `PlatformVersion: ${GlobalSettings.platformVersion ?? ''}`,
            `Backend: ${this.backendUrl}`,
            `Netzwerk: ${navigator.onLine ? 'verbunden' : 'offline'}`,
            `Zeitpunkt: ${new Date()}`,
            `Zeitpunkt nach ISO 8601: ${new Date().toISOString()}`,
        ];

        const copyText = infos.filter(x => !HelperService.isNullOrEmpty(x)).join('\n');
        
        this.clipboard.copy(copyText);
        this.notification.toast(this.translate.instant('components.imprint.supportCard.copyMsg'));
    }

}
