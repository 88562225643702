import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * validator um zwei form controls zu vergleichen
 */
export class CompareValidation {
    
     /**
      * Vergleich ob die Werte von zwei Controls gleich sind
      * 
      * @param  {AbstractControl} otherControl das andere Control
      * @returns {ValidatorFn} ValidatorFn
      */
     public static passwordCompare(otherControl: AbstractControl): ValidatorFn {
        return (control: AbstractControl) => {
            if (!!otherControl && control.value !== otherControl.value) {
                control.setErrors({ matchPassword: true }, { emitEvent: false });
                return { matchPassword: true };
            }
            else {
                return null;
            }
        };
    }
}
