import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GlobalSettings } from '@ucba/sdk';
import { Role } from '@ucba/sdk/enums';
import { IPGToken, IToken } from '@ucba/sdk/interfaces';
import { ILoginResponse } from '@ucba/sdk/responses';
import { HelperService } from '@ucba/sdk/services';
import { Dispose } from '@ucba/sdk/statemanagement/actions';
import { Observable, Subscription, of, take, timer } from 'rxjs';

import { environment } from '../../../environments/environment';
import { SetToken } from '../../statemanagement/actions';
import { ApplicationState } from '../../statemanagement/states';

/**
 * Service welcher die Authentifizierung verwaltet
 */
@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {

    /**
     * Getter für die aktuellen Rechte Rollen
     * 
     * @returns {Role[]} aktuelle Rollen
     */
    private get currentRoles(): Role {
        return this.store.selectSnapshot(ApplicationState.roles);
    }

    private tokenExpireTimerSubscription?: Subscription;

    /**
     * Standard Konstruktor
     * 
     * @param {Store} store Store injector
     */
    public constructor(private store: Store) { }


    /**
     * Externer Login über SAML2 Provider
     *
     * @param {string} jwt JSON Web Token vom Backend
     * @returns {Observable} Rückgabewert
     */
    public static externalLogin(jwt: string): Observable<IPGToken | null> {
        if (jwt === undefined) {
            return of(null);
        }

        const userDataParsed = GlobalSettings.parseTokenData(jwt);

        return of(userDataParsed);
    }


    /**
     * Login über alte Login logik
     * 
     * @param {ILoginResponse} result Response login action
     */
    public appLogin(result: ILoginResponse): void {
        const userDataParsed = GlobalSettings.parseTokenData(result.token);

        const token: IToken = {
            token: result.token,
            expire: userDataParsed.exp.toISOString(),
            created: new Date().toISOString(),
        };

        this.setSubscriptions(token);
        this.store.dispatch(new SetToken(token, result.user.id, userDataParsed.role));
        GlobalSettings.token = result.token;
    }

    /**
     * prüft ob der Nutzer mind. eine der angeforderten Rollen besitzt
     * 
     * @param {Role[]} requestedRoles angeforderte Rollen
     * @returns {boolean} Nutzer besitzt mind. eine der Rollen
     */
    public hasAtLeastOneRole(requestedRoles: Role[]): boolean {
        return !Array.isArray(requestedRoles) || requestedRoles
            .some(reqRole => HelperService.hasFlag(this.currentRoles, reqRole));
    }

    /**
     * prüft ob der Nutzer alle der angeforderten Rollen besitzt
     * 
     * @param {Role[]} requestedRoles angeforderte Rollen
     * @returns {boolean} Nutzer besitzt alle der Rollen
     */
    public hasAllRoles(requestedRoles: Role[]): boolean {
        return !Array.isArray(requestedRoles) || Array.isArray(this.currentRoles) && requestedRoles
            .every(reqRole => HelperService.hasFlag(this.currentRoles, reqRole));
    }

    /**
     * Setzt die Subscriptions für das Ablaufen von Tokens
     *
     * @param {IToken | undefined} token Authentifizierungstoken
     */
    public setSubscriptions(token?: IToken): void {
        if (this.tokenExpireTimerSubscription !== undefined) {
            this.tokenExpireTimerSubscription.unsubscribe();
            this.tokenExpireTimerSubscription = undefined;
        }

        if (!!token && new Date(token.expire) > new Date()) {
            this.tokenExpireTimerSubscription = timer(new Date(token.expire)).pipe(
                take(1),
            ).subscribe(() => {
                this.logout();
            });
        }
    }

    /**
     * loggt den Nutzer aus und beendet alle Verbindungen
     */
    public logout(): void {
        const id = this.store.selectSnapshot(ApplicationState.userId);

        this.store.dispatch(Dispose);
        GlobalSettings.token = null;
        window.open(`${environment.backendUrl}/Authorization/LogoutWithExternalAccount?Id=${id}`, '_self');
    }
}
