import { Role } from '@ucba/sdk/enums';
import { IToken } from '@ucba/sdk/interfaces';

/**
 * Action den Token und UserId Setzt
 */
export class SetToken {
    public static readonly type = '[Application] SET_TOKEN';

    /**
     * Standard Konstruktor
     * 
     * @param {IToken} token der authentifizierungs token
     * @param {string} userId id des Nutzers
     * @param {Role[]} roles rechte rollen des Nutzers
     */
    public constructor(public token: IToken | null, public userId: string, public roles: Role) { }

}
