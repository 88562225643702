import { Component } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

/**
 * Komponente zur Überladung der Standardlabels der Paginator-Komponente für Übersetzungen
 */
@Component({
    selector: 'finad-custom-paginator',
    templateUrl: './custom-paginator.component.html',
    styleUrls: ['./custom-paginator.component.scss'],
})
export class CustomPaginatorComponent extends MatPaginatorIntl {

    public override firstPageLabel: string;
    public override lastPageLabel: string;
    public override itemsPerPageLabel: string;
    public override nextPageLabel: string;
    public override previousPageLabel: string;
    public rangeLabel: string;

    /**
     * Default Konstruktor
     * 
     * @param {TranslateService} translate translate Injektor
     */
    public constructor(private translate: TranslateService) {
        super();
        this.firstPageLabel = this.translate.instant('global.paginatorLabels.firstPageLabel');
        this.lastPageLabel = this.translate.instant('global.paginatorLabels.lastPageLabel');
        this.itemsPerPageLabel = this.translate.instant('global.paginatorLabels.itemsPerPageLabel');
        this.nextPageLabel = this.translate.instant('global.paginatorLabels.nextPageLabel');
        this.previousPageLabel = this.translate.instant('global.paginatorLabels.previousPageLabel'); 
        this.rangeLabel = this.translate.instant('global.paginatorLabels.rangeLabel'); 
    }

    /**
     * Zur Rückgabe der Reichweite der Einträge auf der aktuellen Seite
     * 
     * @param {number} page Seitenzahl
     * @param {number} pageSize Einträge pro Seite
     * @param {number} length Anzahl der Einträge insgesamt
     * @returns {string} Reichweitenlabel 
     */
    public override getRangeLabel = (page: number, pageSize: number, length: number): string => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.rangeLabel} ${length}`;
        }

        const startIndex: number = (page * pageSize) + 1;
        const endIndex: number = Math.min((page + 1) * pageSize, length);

        return `${startIndex} - ${endIndex} ${this.rangeLabel} ${length}`;
    };
}
