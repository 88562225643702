<mat-toolbar class="header mat-elevation-z3 header-toolbar">
    <div fxFlex="33%" fxLayout="row" fxLayoutAlign="start center">
        <button *ngIf="showBackButton" (click)="back()" [matTooltip]="toolTipMsg" mat-button>
            <span class="material-icons md-36">arrow_back</span>
        </button>
        <ng-content select="[left]"></ng-content>
    </div>
    <div fxFlex="33%" fxLayout="row" fxLayoutAlign="center center">
        <span *ngIf="(customerName$ | async) as customerName; else displayName">{{ customerName }}</span>
        <ng-template #displayName><span>{{ displayName$ | async }}</span></ng-template>
        <ng-content select="[center]"></ng-content>
    </div>
    <div fxFlex="33%" fxLayout="row" fxLayoutAlign="end center">
        <div fxLayout="row" fxLayoutAlign="center center">
            <ng-container *ngIf="isSmallScreen; else ElseBlock">
                <button mat-icon-button fxLayoutAlign="center center" [matMenuTriggerFor]="menu">
                    <mat-icon>
                        menu
                    </mat-icon>
                </button>
                <mat-menu #menu="matMenu" panelClass="header-mat-menu" MatMenuDe>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <ng-template *ngTemplateOutlet="headerButtonsRight"></ng-template>
                    </div>
                </mat-menu>
            </ng-container>
            <ng-template #ElseBlock>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <ng-template *ngTemplateOutlet="headerButtonsRight"></ng-template>
                </div>
            </ng-template>
        </div>
    </div>
</mat-toolbar>

<ng-template #headerButtonsRight>
    <ng-content select="[right]"></ng-content>
    <button *ngIf="!hideCalculatorButton" (click)="openCalculator()"
        matTooltip="{{ 'components.dashboard.buttons.calculateTooltip' | translate }}" mat-button>
        <span class="material-icons md-36">calculate</span>
    </button>
    <button *ngIf="showHomeButton" (click)="home()" mat-button
        matTooltip="{{ 'components.header.homeTooltip' | translate }}">
        <span class="material-icons md-36">home</span>
    </button>
    <button *ngIf="showLogoutButton" (click)="logout()" mat-button
        matTooltip="{{ 'components.header.logoutTooltip' | translate }}">
        <span class="material-icons md-36">logout</span>
    </button>
</ng-template>