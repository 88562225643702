<finad-header [showLogoutButton]="true">
    <ng-container right>
        <button matTooltip="{{ 'components.dashboard.buttons.manualTooltip' | translate }}" (click)="manual()"
            mat-button>
            <span class="material-icons md-36">help_center</span>
        </button>
        <button matTooltip="{{ 'components.dashboard.buttons.infoTooltip' | translate }}" (click)="imprint()"
            mat-button>
            <span class="material-icons md-36">info</span>
        </button>
        <button matTooltip="{{ 'components.dashboard.buttons.releaseNotesTooltip' | translate }}"
            (click)="releaseNotes()" mat-button>
            <span class="material-icons md-36">event_note</span>
        </button>
        <button *ngIf="showUserManagementButton" (click)="openUserManagement()"
            matTooltip="{{ 'components.dashboard.buttons.userManagementTooltip' | translate }}" mat-button>
            <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Manage-Accounts" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path
                        d="M16.5,25.5 C16.5,27.549851 17.1492273,29.4480668 18.2532761,31.0002417 L3,31 L3.00385278,30.7192951 C3.15250293,25.3104549 7.58382456,20.9714173 13.0285827,20.9714173 L13.0285827,20.9714173 L17.6468041,20.9713578 C16.9154386,22.3175332 16.5,23.8602512 16.5,25.5 Z M17,5 C20.5898509,5 23.5,7.91014913 23.5,11.5 C23.5,15.0898509 20.5898509,18 17,18 C13.4101491,18 10.5,15.0898509 10.5,11.5 C10.5,7.91014913 13.4101491,5 17,5 Z"
                        id="Combined-Shape" fill="#262626"></path>
                    <g id="Navigation-and-action/Settings" transform="translate(17.500000, 16.000000)" fill="#262626">
                        <path
                            d="M10.2883111,11.4513973 C9.21035305,11.886833 7.98478053,11.3670393 7.54813931,10.2889947 C7.11273855,9.21095006 7.63373092,7.98403849 8.71168893,7.54860274 C9.78964695,7.11316699 11.0152195,7.63420122 11.4506202,8.71224586 C11.886021,9.7902905 11.3650286,11.0159615 10.2883111,11.4513973 L10.2883111,11.4513973 Z M16.4372615,13.4784685 L17.2038645,11.6461648 L15.4585401,10.1215194 L15.4585401,8.92314067 L17.21875,7.40717916 L16.4645515,5.56991321 L14.1436546,5.72622348 L13.305105,4.88636592 L13.4775286,2.56156079 L11.6453721,1.79613669 L10.1134065,3.55028528 L8.93000954,3.55028528 L7.4067271,1.78125 L5.56960878,2.53550908 L5.72590649,4.85535198 L4.88735687,5.69520954 L2.56149809,5.52153146 L1.79489504,7.35383518 L3.54145992,8.87848059 L3.54145992,10.0780999 L1.78125,11.5928208 L2.53544847,13.4313273 L4.83773855,13.2750171 L5.69365458,14.1310019 L5.52123092,16.4384392 L7.35462786,17.2051039 L8.8692271,15.4683231 L10.084876,15.4683231 L11.5932729,17.21875 L13.4291508,16.4657315 L13.2740935,14.1620158 L14.1300095,13.3072716 L16.4372615,13.4784685 Z"
                            id="259"></path>
                    </g>
                </g>
            </svg>
        </button>
    </ng-container>
</finad-header>

<finad-function-menu [navigationButtons]="navButtons" (navigationBtnClicked)="onNavigationBtnClicked($event)">
    <div class="content">
        <mat-card>
            <mat-card-header class="header-container">
                <mat-card-title [ngClass]="{'title-remove-margin-bottom': isSmallScreen}">
                    <span>{{ 'components.dashboard.activities.title' | translate}}</span>
                </mat-card-title>
                <div *ngIf="isSmallScreen" fxLayoutAlign="space-evenly center">
                    <button mat-button (click)="resetAllFilters()" type="button"
                        [matTooltip]="'global.buttons.resetFilter' | translate">
                        <span class="material-icons md-36">delete_outline</span>
                    </button>
                </div>
            </mat-card-header>
            <div fxLayout="column" fxLayoutAlign="start stretch" class="activity-container">
                <div fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-evenly none">
                    <div fxFlex="20">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ 'components.dashboard.activities.titleBranch' | translate}} </mat-label>
                            <span matPrefix class="material-icons">filter_list &nbsp;</span>
                            <mat-select multiple [(value)]="UserSettings.activityFilterBranch"
                                (selectionChange)="refreshActivityList()">
                                <mat-option *ngFor="let entity of filterListBranch" [value]="entity.value">
                                    {{entity.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="20">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ 'components.dashboard.activities.titleOwner' | translate}} </mat-label>
                            <span matPrefix class="material-icons">filter_list &nbsp;</span>
                            <mat-select [(value)]="UserSettings.activityFilterConsult"
                                (selectionChange)="refreshActivityList()">
                                <mat-option *ngFor="let entity of filterListConsult" [value]="entity.value">
                                    {{entity.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="20">
                        <mat-form-field appearance="fill">
                            <mat-label>{{ 'components.dashboard.activities.titleSort' | translate}} </mat-label>
                            <span matPrefix class="material-icons">sort &nbsp;</span>
                            <mat-select [(value)]="UserSettings.activitySort" (selectionChange)="refreshActivityList()">
                                <mat-option *ngFor="let entity of sortList" [value]="entity.value">
                                    {{entity.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="25" fxLayout="row" fxLayoutAlign="space-between">
                        <div [fxFlex]="!isSmallScreen ? 95 : 100">
                            <mat-form-field appearance="fill" class="search-field">
                                <mat-label>{{ 'components.dashboard.activities.titleSearch' | translate}} </mat-label>
                                <span matPrefix class="material-icons">search &nbsp;</span>
                                <input matInput type="text" [formControl]="activitySearch">
                                <button mat-button *ngIf="UserSettings.activitySearch" matSuffix mat-icon-button
                                    aria-label="Clear" (click)="clearSearch()" type="button">
                                    <span class="material-icons">close</span>
                                </button>
                            </mat-form-field>
                        </div>
                        <div *ngIf="!isSmallScreen" fxLayoutAlign="space-evenly center">
                            <button mat-button (click)="resetAllFilters()" type="button"
                                [matTooltip]="'global.buttons.resetFilter' | translate">
                                <span class="material-icons md-36">delete_outline</span>
                            </button>
                        </div>
                    </div>
                </div>

                <mat-progress-bar class="progess-bar" [class.progess-bar-visible]="isLoading" mode="query">
                </mat-progress-bar>

                <div fxLayout="column" fxFlex class="activity">
                    <div fxLayout="row" class="activity-header">
                        <span fxFlex="50" fxFlex.gt-sm="20">
                            {{ 'components.dashboard.tableHeader.name' | translate }}
                        </span>
                        <span fxFlex="15" fxHide fxShow.gt-md>
                            {{ 'components.dashboard.tableHeader.lastChange' | translate }}
                        </span>
                        <span fxFlex="50" fxFlex.gt-md="15">
                            {{ 'components.dashboard.tableHeader.finNo' | translate }}
                        </span>
                        <span fxFlex="15" fxHide fxShow.gt-md>
                            {{ 'components.dashboard.tableHeader.cusNo' | translate }}
                        </span>
                        <span fxFlex="15" fxHide fxShow.gt-md>
                            {{ 'components.dashboard.tableHeader.branch' | translate }}
                        </span>
                        <span fxFlex="10" fxHide fxShow.gt-md>
                            {{ 'components.dashboard.tableHeader.consult' | translate }}
                        </span>
                    </div>
                    <mat-accordion multi [togglePosition]="'before'">
                        <cdk-virtual-scroll-viewport itemSize="20">
                            <mat-expansion-panel *cdkVirtualFor="let cluster of displayedActivities"
                                [(expanded)]="cluster.open" class="activity-panel">
                                <mat-expansion-panel-header class="disable-ripple">
                                    <span fxFlex="50" fxFlex.gt-md="20">{{ cluster.customerName }}</span>
                                    <span fxFlex="15" fxHide fxShow.gt-md>
                                        {{ cluster.lastModified | date:'dd.MM.yyyy HH:mm' }}
                                    </span>
                                    <span fxFlex="50" fxFlex.gt-sm="15">{{ cluster.financingNumber }}</span>
                                    <span fxFlex="15" fxHide fxShow.gt-md>{{ cluster.customerNumber }}</span>
                                    <span fxFlex="15" fxHide fxShow.gt-md>{{ cluster.branchName }}</span>
                                    <span fxFlex="10" fxHide fxShow.gt-md [matTooltip]="cluster.consultantName">
                                        {{ cluster.consultantShortName }}
                                    </span>
                                    <small fxHide fxShow.gt-md class="activity-count"
                                        *ngIf="countUnreadActivities(cluster) !== 0"
                                        matTooltip="{{ 'components.dashboard.activities.activityCountTooltip' | translate }}">
                                        {{ countUnreadActivities(cluster) }}
                                    </small>

                                    <a class="material-icons"
                                        (click)="onFinancingMap(cluster.customerId, cluster.financingMapId, cluster.data)"
                                        matTooltip="{{ 'components.dashboard.activities.openFinancingTooltip' | translate }}">arrow_forward
                                        &nbsp;</a>
                                </mat-expansion-panel-header>
                                <div fxLayout="row" class="activity-body">
                                    <small fxFlex="80" fxFlex.gt-sm="30">
                                        {{ 'components.dashboard.tableHeader.action' | translate }}
                                    </small>
                                    <small fxFlex="30" fxHide fxShow.gt-md>
                                        {{ 'components.dashboard.tableHeader.activityCreate' | translate }}
                                    </small>
                                    <small fxFlex="30" fxHide fxShow.gt-md>
                                        {{ 'components.dashboard.tableHeader.actionBy' | translate }}
                                    </small>
                                </div>
                                <div *ngFor="let activity of cluster.data" fxLayout="row">
                                    <span fxFlex="80" fxFlex.gt-sm="30">{{ activity.description}}</span>
                                    <span fxFlex="30" fxHide fxShow.gt-md>
                                        {{ activity.created | date:'dd.MM.yyyy HH:mm' }}
                                    </span>
                                    <span fxFlex="30" fxHide fxShow.gt-md>
                                        {{ activity.executiveSystem | finprocessEnumTranslation:'SystemType'}}
                                    </span>
                                    <mat-checkbox color="primary" [checked]="activity.isRead"
                                        (change)="updateActivity(activity, $event)"></mat-checkbox>
                                </div>
                            </mat-expansion-panel>
                        </cdk-virtual-scroll-viewport>
                    </mat-accordion>
                    <mat-paginator #paginator [length]="UserSettings.activityPaging.totalResultCount"
                        [pageSize]="UserSettings.activityPaging.entityCountPerPage" [showFirstLastButtons]="true"
                        [pageSizeOptions]="GlobalSettings.pageSizeOptions"
                        [pageIndex]="UserSettings.activityPaging.currentPage-1" (page)="handlePageEvent($event)">
                    </mat-paginator>
                </div>
            </div>
        </mat-card>
    </div>
</finad-function-menu>