import { Injectable } from '@angular/core';
import { HubConnectionBuilder } from '@microsoft/signalr';

/**
 * Erstellt instanzen von Klassen, welche über ein extenes Plugin eingebunten werden.
 * Wird verwendet um diese mocken zu können
 */
@Injectable({
    providedIn: 'root',
})
export class ThirdPartyFactoryService {

    private hubConnectionBuilderInternal: HubConnectionBuilder | undefined;

    /**
     * Singelton für einen HubConnectionBuilder
     * 
     * @returns {HubConnectionBuilder} eine Singelton instance des HubConnectionBuilder
     */
    public get hubConnectionBuilder(): HubConnectionBuilder {
        if (this.hubConnectionBuilderInternal === undefined) {
            this.hubConnectionBuilderInternal = new HubConnectionBuilder();
        }

        return this.hubConnectionBuilderInternal;
    }
}
