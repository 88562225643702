import { Component } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * Dialog um einen beliebigen Inhalt anzuzeigen
 */
@Component({
    selector: 'finad-blank-dialog',
    templateUrl: './blank-dialog.component.html',
    styleUrls: ['./blank-dialog.component.scss'],
})
export class BlankDialogComponent {

    public form: FormGroup;
    
    /**
     * Standard Konstruktor
     * 
     * @param {MatDialogRef} dialogRef MatDialogRef injector
     * @param {UntypedFormBuilder} fb UntypedFormBuilder injector
     */
    public constructor(
        private dialogRef: MatDialogRef<BlankDialogComponent>,
        private fb: UntypedFormBuilder,
    ) {
        this.form = this.fb.group({
            data: [null],
        })
    }

    /**
     * Speichert das aktuelle Formular
     */
    public onSave() {
        this.dialogRef.close(this.form.value);
    }
}
