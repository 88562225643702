import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { EnumTranslationModule } from '@ntag-ef/finprocess-enums';
import { ComponentsModule } from '@ucba/components';
import { FlexLayoutModule } from 'ngx-flexible-layout';

import { ChatComponent } from './components/chat/chat.component';
import { FunctionMenuComponent } from './components/function-menu/function-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { LocationsComponent } from './components/locations/locations.component';

/**
 * Shared Select- Komponente
 */
@NgModule({
    declarations: [
        HeaderComponent,
        FunctionMenuComponent,
        ChatComponent,
        LocationsComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatMenuModule,
        MatSidenavModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        MatTooltipModule,
        FlexLayoutModule,
        MatBadgeModule,
        TranslateModule.forChild(),
        MatIconModule,
        MatSlideToggleModule,
        FormsModule,
        ReactiveFormsModule,
        EnumTranslationModule.forChild(),
    ],
    exports: [
        HeaderComponent,
        FunctionMenuComponent,
        ChatComponent,
        LocationsComponent,
    ],
    providers: [
        {
            provide: MAT_MENU_DEFAULT_OPTIONS,
            useValue: {
                overlapTrigger: false,
                xPosition: 'after',
                yPosition: 'below',
                backdropClass: 'cdk-overlay-transparent-backdrop',
                overlayPanelClass: 'custom-overlay-menu',
            },
        },
    ],
})
export class SharedModule { }
