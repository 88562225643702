import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';

import { ILocationsData } from './locations-data.interface';

/**
 * Komponente, welches einen generischen bottom sheet bereitstellt
 * 
 * @see https://coder.haus/2020/01/06/easy-angular-testing-ui-elements-bottom-sheet/
 */
@Component({
    selector: 'finad-locations',
    templateUrl: './locations.component.html',
    styleUrls: ['./locations.component.scss'],
})
export class LocationsComponent {

    public locales: Array<ILocationsData> | undefined;

    /**
     * Standard Konstruktor zum initialisieren der default values
     *
     * @param {MatBottomSheet} bottomSheet MatBottomSheet injecton
     * @param {Array} data data injection
     */
    public constructor(
        private bottomSheet: MatBottomSheet,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: Array<ILocationsData>,
    ) { this.locales = this.data; }

    /**
     * zum schließen des bottom sheets
     */
    public closeComponentSheetMenu(): void {
        this.bottomSheet.dismiss();
    }

     /**
      * zum schließen des bottom sheets
      * 
      * @param {string} placeId place id
      */
    public listItemClicked(placeId: string): void {
        this.bottomSheet.dismiss(placeId);
    }

}
