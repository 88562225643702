<finad-header [showBackButton]="true" [showHomeButton]="true"
    [toolTipMsg]="'components.imprint.imprintBackToolTip' | translate">
</finad-header>
<div class="imprint-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                {{ 'components.imprint.supportCard.title' | translate }}
            </mat-card-title>
        </mat-card-header>
        <button color="primary" class="copy-button" (click)="copySystemInfo()" mat-raised-button>
            {{ 'components.imprint.supportCard.copySystemInfoBtn' | translate }}
            <mat-icon iconPositionEnd class="copy-icon"> content_copy </mat-icon>
        </button>
        <mat-card-content>
            <div>
                <p>{{ 'components.imprint.phone' | translate }}:
                    <a href="tel:{{ 'components.imprint.supportCard.phoneNumber' | translate }}">
                        {{ 'components.imprint.supportCard.phoneNumber' | translate }}
                    </a>
                </p>
                <p (click)="generateTestError()">
                    {{ 'components.imprint.supportCard.appVersion' | translate}}: {{ version }}
                    <span *ngIf="showMetaData">
                        {{ backendUrl }} {{ buildDate }}
                    </span>
                </p>
                <p class="subtitle">{{ 'components.imprint.supportCard.system' | translate }}</p>
                <p>
                    {{ browser.microsoft }}
                    <br>
                    {{ browser.chrome }}
                </p>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card>
        <mat-card-header>
            <mat-card-title>{{ 'components.imprint.imprintCard.title' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div>
                <p>{{ 'components.imprint.imprintCard.declarations' | translate }}:
                    <br>{{ 'components.imprint.imprintCard.company' | translate }}
                    <br>{{ 'components.imprint.imprintCard.address' | translate }}
                </p>

                <p class="subtitle">{{ 'components.imprint.imprintCard.contact' | translate }}:</p>
                <p>{{ 'components.imprint.fax' | translate }} {{ 'components.imprint.imprintCard.faxNumber1' | translate
                    }}
                    <br>{{ 'components.imprint.fax' | translate }} {{ 'components.imprint.imprintCard.faxNumber2' |
                    translate }}
                    <br>{{ 'components.imprint.mail' | translate }} {{ 'components.imprint.imprintCard.mailAddress' |
                    translate }}
                </p>

                <p class="subtitle">{{ 'components.imprint.imprintCard.representative' | translate }}:</p>
                <p>{{ 'components.imprint.imprintCard.person1' | translate }}
                    <br>{{ 'components.imprint.imprintCard.person2' | translate }}
                    <br>{{ 'components.imprint.imprintCard.person3' | translate }}
                </p>

                <p class="subtitle">{{ 'components.imprint.imprintCard.register' | translate }}:</p>
                <p>{{ 'components.imprint.imprintCard.registerCourt' | translate }}
                    <br>{{ 'components.imprint.imprintCard.registerNumber' | translate }}
                </p>

                <p class="subtitle">{{ 'components.imprint.imprintCard.tax' | translate }}:</p>
                <p>{{ 'components.imprint.imprintCard.taxLaw' | translate }}
                    <br>{{ 'components.imprint.imprintCard.taxNumber' | translate }}
                </p>

                <p class="subtitle">{{ 'components.imprint.imprintCard.responsible' | translate }}:</p>
                <p>{{ 'components.imprint.imprintCard.responsiblePerson' | translate }}</p>

                <p class="subtitle">{{ 'components.imprint.imprintCard.dispute' | translate }}:</p>
                <p>{{ 'components.imprint.imprintCard.disputeText1' | translate }}
                    <a target="_blank" href="{{ 'components.imprint.imprintCard.disputeLink' | translate }}">
                        {{ 'components.imprint.imprintCard.disputeLink' | translate }}
                    </a>
                    {{ 'components.imprint.imprintCard.disputeText2' | translate }}
                    <br>
                    <br>{{ 'components.imprint.imprintCard.disputeText3' | translate }}
                </p>

                <p class="subtitle">{{ 'components.imprint.imprintCard.liabilityContent' | translate }}</p>
                <p>{{ 'components.imprint.imprintCard.liabilityContentText1' | translate }}
                    <br>
                    <br>{{ 'components.imprint.imprintCard.liabilityContentText2' | translate }}
                </p>

                <p class="subtitle">{{ 'components.imprint.imprintCard.liabilityLinks' | translate }}</p>
                <p>{{ 'components.imprint.imprintCard.liabilityLinksText1' | translate }}
                    <br>
                    <br>{{ 'components.imprint.imprintCard.liabilityLinksText2' | translate }}
                </p>

                <p class="subtitle">{{ 'components.imprint.imprintCard.copyright' | translate }}</p>
                <p>{{ 'components.imprint.imprintCard.copyrightText1' | translate }}
                    <br>
                    <br>{{ 'components.imprint.imprintCard.copyrightText2' | translate }}
                </p>
            </div>
        </mat-card-content>
    </mat-card>
</div>