import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { environment } from '../../../environments/environment';

/**
 * ein translation loader, welcher die übersetzungen läd, bevor die Anwendung startet
 */
@Injectable({
    providedIn: 'root',
})
export class EarlyTranslationLoaderService extends TranslateLoader {
    /**
     * gecachte übersetzungen
     */
    private static translations: { [index: string]: Record<string, unknown> } = {};

    /**
     * standard Konstruktor
     * 
     * @param {HttpClient} http HttpClient injector
     */
    public constructor(private http: HttpClient) {
        super();
    }

    /**
     * Cache all the languaes
     * 
     * @returns {Promise<void>}
     */
    public async cache(): Promise<void> {
        for (const language of environment.supportedLanguages) {
            try {
                EarlyTranslationLoaderService.translations[language] = (await this.http.get(`./assets/i18n/${language}.json`).toPromise()) as Record<string,unknown>;
            }
            catch (error) {
                // eslint-disable-next-line no-console
                console.error(`Failed to fetch language ${language} with error ${error}`);
                EarlyTranslationLoaderService.translations[language] = {};
            }
        }
    }

    /**
     * Supply the early fetched translation
     * 
     * @param {string} lang key der angeforderten Sprache
     * @returns {Observable<Record<string, any>>} angeforderte Sprache
     */
    // eslint-disable-next-line class-methods-use-this
    public getTranslation(lang: string): Observable<Record<string, unknown>> {
        if (lang in EarlyTranslationLoaderService.translations) {
            return of(EarlyTranslationLoaderService.translations[lang]);
        }
        else if (lang.includes('-') && (lang.substr(0, 2) in EarlyTranslationLoaderService.translations)) {
            return of(EarlyTranslationLoaderService.translations[lang.substr(0, 2)]);
        }
        else {
            return of({});
        }
    }
}
