<div class="chat-container">
    <!-- header -->
    <div class="header" fxLayout="row" fxLayoutAlign="space-between start">
        <div class="header-left">
            <button mat-button (click)="downloadChatHistory()" [disabled]="!isDownloadBtnActive">
                <mat-icon *ngIf="!isDownloadLoading" class="download-icon">file_download</mat-icon>
                <mat-spinner *ngIf="isDownloadLoading" diameter="30"></mat-spinner>
            </button>
        </div>
        <div class="header-right">
            <h2>{{ 'components.chat.messages' | translate }}</h2>
            <p *ngIf="!isInternal">{{ 'components.chat.customerChat' | translate }}</p>
            <p *ngIf="isInternal">{{ 'components.chat.internalChat' | translate }}</p>
        </div>
    </div>
    <!-- body -->
    <div class="msg-container" #scrollContrainer [ngClass]="{'without-footer':open}">
        <ng-container *ngIf="!isInternal">
            <div *ngFor="let msg of (chatMessagesGroup$ | async); let last = last" class="msg"
                [ngClass]="msg.from === systemType.Finadvisory && msg.userId === userId ? 'sent-msg' : 'recived-msg'">
                <div class="msg-header" *ngIf="!!msg.from">
                    <span>{{msg.displayName}}</span>
                    <button class="del-btn" matTooltip="{{ 'components.chat.deleteMsg' | translate }}" class="del-btn"
                        mat-icon-button *ngIf="msg.userId === userId && open && !msg.markedDeleted"
                        (click)="markDeleted(msg.id!)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <span [class.msg-deleted]="msg.markedDeleted" [innerHTML]="msg.text"></span>
                <span class="msg-date">{{msg.created | date:'dd.MM.yyyy, HH:mm'}}</span>
            </div>
        </ng-container>

        <ng-container *ngIf="isInternal">
            <div *ngFor="let msg of (chatMessagesInternal$ | async); let last = last" class="msg"
                [ngClass]="msg.from === systemType.Finadvisory && msg.userId === userId ? 'sent-msg' : 'recived-msg'">
                <span class="msg-sender" *ngIf="!!msg.from">{{msg.displayName}}</span>
                <span [innerHTML]="msg.text"></span>
                <span class="msg-date">{{msg.created | date:'dd.MM.yyyy, HH:mm'}}</span>
            </div>
        </ng-container>
    </div>

    <form [class.hide]="isChatOnlyReadable" *ngIf="!!chatForm" [formGroup]="chatForm" #form="ngForm"
        [ngClass]="{'without-toggle':open}">
        <mat-form-field appearance="fill">
            <div class="field-wrap">
                <textarea placeholder="{{ 'components.chat.placeholder' | translate }}" class="input-set scroll"
                    rows="3" matInput formControlName="txArea" maxlength="2500"></textarea>
                <button *ngIf="!isSendLoading" matSuffix mat-button class="send-btn" (click)="onSend()"
                    [disabled]="!chatForm.controls['txArea'].value?.trim()">
                    <mat-icon class="send-icon">send</mat-icon>
                </button>
                <mat-spinner *ngIf="isSendLoading" diameter="30"></mat-spinner>
            </div>
            <span class="remaining-chars">
                {{ 'components.chat.remainingChars' | translate:{'count' :
                2500-chatForm.controls['txArea'].value?.length} }}
            </span>
        </mat-form-field>
    </form>
    <mat-slide-toggle (click)="onToggleCustomerInternal()" labelPosition="before" [class.invisible]='open'>
        <span *ngIf="!isInternal">{{ 'components.chat.changeToInternalChat' | translate }}</span>
        <span *ngIf="isInternal">{{ 'components.chat.changeToCustomerChat' | translate }}</span>
    </mat-slide-toggle>
</div>