import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EnumTranslationService } from '@ntag-ef/finprocess-enums';
import { NotificationService } from '@ntag-ef/notifications';
import { GlobalSettings } from '@ucba/sdk';
import { AccountController } from '@ucba/sdk/controller';
import { GoogleService, HelperService, HubService } from '@ucba/sdk/services';
import { NGXLogger } from 'ngx-logger';
import { catchError, interval, of, switchMap, tap } from 'rxjs';

import { environment } from '../environments/environment';

/**
 * AppComponent Klasse
 */
@Component({
    selector: 'finad-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {

    @ViewChild('googleMap', { static: false }) public googleMap: ElementRef | undefined;

    /**
     * der intervall in dem Backend-ping angestoßen wird
     */
    private backendPingInterval = 20000;
    private pingIntervalStarted = false; // Flag to track if ping interval has been started
    /**
     * ob die App gesperrt ist
     */
    public lockApp?: boolean;

    /**
     * standard Konstruktor, initialisiert TranslateService
     *
     * @param {TranslateService} translateService TranslateService injector
     * @param {EnumTranslationService} enumTranslationService EnumTranslationService injector
     * @param {HubService} hubService HubService injector
     * @param {GoogleService} googleService GoogleService injector
     * @param {NGXLogger} logger NGXLogger injector
     * @param {NotificationService} notification NotificationService injector
     * @param {MatDialog} dialogRef MatDialog injector
     * @param {AccountController} accountController AccountController injector
     */
    public constructor(
        private translateService: TranslateService,
        private enumTranslationService: EnumTranslationService,
        private hubService: HubService,
        private googleService: GoogleService,
        private logger: NGXLogger,
        private notification: NotificationService,
        private dialogRef: MatDialog,
        private accountController: AccountController,
    ) {
        this.initTranslate();
        this.hubService.initialize();
        this.initNetworkObserver();
        this.initPlatformDetection().catch(e => { throw e; });
    }

    /**
     * inititialisiert den Translation Service
     *
     * @returns {void}
     */
    private initTranslate(): void {
        const browserLang = this.translateService.getBrowserLang();
        const defaultLang = environment.defaultLang;

        this.translateService.setDefaultLang(defaultLang);
        this.enumTranslationService.setDefaultLang(defaultLang);

        // probieren die Browsersprache zu ermitteln und zu setzen
        if (HelperService.hasValue(browserLang) && browserLang !== '') {
            this.translateService.use(browserLang);
            this.enumTranslationService.use(browserLang);
        }
        else {
            // sonst festen Wert setzen
            this.translateService.use(defaultLang);
            this.enumTranslationService.use(defaultLang);
        }
    }

    /**
     * initialisiert einen Netzwerk Observer
     */
    private initNetworkObserver() {
        // prueft ob der Browser online ist und markiert die Anwendung als online wenn das Backend auch online ist. Anschließend wird die Google API gestartet
        HelperService.isBrowserOnlineObservable$
            .subscribe(browserOnline => {
                GlobalSettings.isBrowserOnline = browserOnline;
                GlobalSettings.isOnline.next(browserOnline);

                if (browserOnline && !this.googleService.isLoaded && !this.googleService.isLoading) {
                    this.googleService.initGoogleLoader(GlobalSettings.googleApiKey).then(() => {

                        if (!!this.googleMap) {
                            this.googleService.initPlacesService(this.googleMap.nativeElement);
                        }

                        this.logger.info('Google Place API was loaded');
                    }).catch(e => { throw e; })
                }
            });

        // Überwacht den Online-Status der Anwendung:
        // toggelt die App-Sperrung abhängig vom Online-Status
        // und startet den Ping-Intervall, wenn die Anwendung offline markiert ist (z.B. wegen fehlgeschlagenen request), aber der Browser online ist. (Backend evtl. nicht erreichbar)
        GlobalSettings.isOnline.asObservable()
            .subscribe(isOnline => {
                this.toggleLock(isOnline);

                if (!isOnline && GlobalSettings.isBrowserOnline && !this.pingIntervalStarted) {
                    this.pingIntervalStarted = true;
                    const pingSubscription = interval(this.backendPingInterval).pipe(
                        switchMap(() => this.accountController.ping().pipe(
                            tap(response => {
                                if (response.ok) {
                                    GlobalSettings.isOnline.next(true);
                                    pingSubscription.unsubscribe();
                                    this.pingIntervalStarted = false;
                                }
                            }),
                            catchError(() => of(null)),
                        )),
                    ).subscribe();
                }
            });
    }

    /**
     * wenn die Anwendung offline markiert ist, wird die Anwendung mit einem nicht schließbaren Dialog gesperrt
     * wenn die Anwendung wieder online markiert ist, wird der Dialog geschlossen und die App entsperrt
     * 
     * @param {boolean} isOnline ob die Anwendung online markiert ist
     */
    private toggleLock(isOnline: boolean) {
        if (!isOnline) {
            this.lockApp = true;
            // vermeidet das mehrfache öffnen des Dialogs
            const offlineDialog = this.dialogRef.getDialogById(GlobalSettings.connectionErrorId);
            if (!offlineDialog) {
                this.notification.dialog(this.translateService.instant('applicationTranslations.offline'), this.translateService.instant('applicationTranslations.offlineNote'), [], { closeOnNavigation: false, disableClose: true, id: GlobalSettings.connectionErrorId });
            }
        }
        else {
            this.dialogRef.closeAll();
            this.lockApp = false;
        }
    }

    /**
     * initialisiert die Erkennung der Plattform-Version
     */
    // eslint-disable-next-line class-methods-use-this
    private async initPlatformDetection() {
        if ('userAgentData' in navigator) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const userAgentData = await ((navigator as any).userAgentData.getHighEntropyValues(['platformVersion']));
            const platformVersion = userAgentData.platformVersion;
            GlobalSettings.platformVersion = platformVersion;
        }
    }
}

