import { Component } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MasterDataController } from '@ucba/sdk/controller';
import { IListTuple } from '@ucba/sdk/interfaces';
import { Observable, map } from 'rxjs';

/**
 * Komponente für den Dialog zur Auswahl einer Filiale
 */
@Component({
    selector: 'finad-branch-dialog',
    templateUrl: './branch-dialog.component.html',
    styleUrls: ['./branch-dialog.component.scss'],
})
export class BranchDialogComponent {

    public form: FormGroup | undefined;

    public branchList$: Observable<IListTuple<string>[]> | undefined;


    /**
     * Standard Konstruktor
     * 
     * @param {MatDialogRef<BranchDialogComponent>} dialogRef Referenz auf den Dialog
     * @param {MasterDataController} masterDataController MasterDataController injector
     * @param {UntypedFormBuilder} fb UntypedFormBuilder injector
     */
    public constructor(
        public dialogRef: MatDialogRef<BranchDialogComponent>,
        private masterDataController: MasterDataController,
        private fb: UntypedFormBuilder,
    ) {

        this.branchList$ = this.masterDataController.getBranches()
            .pipe(map(branchArray => branchArray
                .map<IListTuple<string>>(({ name, id }) => ({
                    label: name,
                    value: id,
                }))));

        this.form = this.fb.group({
            branchid: [null, Validators.required],
        }, { updateOn: 'change' });
    }

    /**
     * schließen des Dialogs
     */
    public onCancel(): void {
        this.dialogRef.close();
    }

    /**
     * schließen des Dialogs und gibt den eingegebenen Wert zurück
     */
    public onSave(): void {
        if (!!this.form && this.form.valid) {
            this.dialogRef.close(this.form?.controls['branchid'].value);
        }
    }
}
