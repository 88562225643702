<mat-card>
    <mat-card-header>
        <mat-card-title>{{ 'components.registration.title' | translate}}</mat-card-title>
    </mat-card-header>
    <waiter-component [isLoading]="!branchList$">
        <form *ngIf="!!form && branchList$">
            <ucba-select *ngIf="(branchList$ | async) as branches" class="form-field"
                [control]="form.controls['branchid']" [customLabel]="'components.registration.branch' | translate"
                [array]="branches">
            </ucba-select>

            <ucba-input class="form-field" [control]="form.controls['firstname']"
                [labelText]="'components.registration.firstname' | translate">
            </ucba-input>

            <ucba-input class="form-field" [control]="form.controls['lastname']"
                [labelText]="'components.registration.lastname' | translate">
            </ucba-input>

            <ucba-input class="form-field" [control]="form.controls['email']"
                [labelText]="'components.registration.email' | translate">
            </ucba-input>

            <ucba-input class="form-field" [control]="form.controls['password']" type="password"
                [labelText]="'components.registration.password' | translate">
            </ucba-input>

            <ucba-input class="form-field" [control]="form.controls['confirmpassword']" type="password"
                [labelText]="'components.registration.confirmpassword' | translate">
            </ucba-input>

            <button [disabled]="form.invalid" mat-raised-button color="primary" (click)="registrate()">
                {{ 'components.registration.submitBtn' | translate }}
            </button>
        </form>
    </waiter-component>
</mat-card>