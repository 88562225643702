/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable class-methods-use-this */

import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { GlobalSettings } from '@ucba/sdk';
import { Role } from '@ucba/sdk/enums';
import { IToken } from '@ucba/sdk/interfaces';
import { Dispose } from '@ucba/sdk/statemanagement/actions';

import { SetToken } from './application.actions';

/**
 * Datenmodel für ApplicationState
 */
export interface IApplicationStateModel {
    token: IToken | null;
    userId: string | null;
    roles: Role;
}

/**
 * Klasse des Anwendungs States
 */
@State<IApplicationStateModel>({
    name: ApplicationState.stateName,
    defaults: ApplicationState.defaultData,
})
@Injectable()
export class ApplicationState implements NgxsOnInit {
    public static readonly stateName = 'applicationState';

    private static readonly defaultData: IApplicationStateModel = {
        token: null,
        userId: null,
        roles: Role.None,
    };

    /**
     * initialized state
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @see https://ngxs.gitbook.io/ngxs/advanced/life-cycle#ngxsoninit
     */
    public ngxsOnInit({ getState }: StateContext<IApplicationStateModel>): void {
        const state = getState();
        GlobalSettings.token = state.token?.token;
    }

    /**
     * Setzt den User token
     * 
     * @param {StateContext} ctx aktueller State Kontext
     * @param {SetToken} action SetToken Action
     */
    @Action(SetToken)
    public setToken(
        { patchState }: StateContext<IApplicationStateModel>,
        { token, userId, roles }: SetToken,
    ): void {
        patchState({
            token,
            userId,
            roles,
        });
    }

    /**
     * zurücksetzen des States
     * 
     * @param {StateContext} ctx aktueller State Kontext
     */
    @Action(Dispose)
    public dispose({ setState }: StateContext<IApplicationStateModel>): void {
        setState({ ...ApplicationState.defaultData });
    }

    /**
     * prüft ob ein authentifizierungstoken vorliegt
     * 
     * @param {IApplicationStateModel} state aktueller State
     * @returns {boolean} ist ein Token gesetzt
     */
    @Selector()
    public static isLoggedIn(state: IApplicationStateModel): boolean {
        return !!state.token && !!state.userId && state.token.expire > new Date().toISOString();
    }

    /**
     * gibt die Id des Users zurück
     * 
     * @param {IApplicationStateModel} state aktueller State
     * @returns {string} die aktuelle UserId
     */
    @Selector()
    public static userId(state: IApplicationStateModel): string | null {
        return state.userId;
    }

    /**
     * gibt den auth token zurück
     * 
     * @param {IApplicationStateModel} state aktueller State
     * @returns {string} auth token
     */
    @Selector()
    public static token(state: IApplicationStateModel): string | null {
        return state.token?.token ?? null;
    }

    /**
     * die aktuell gesetzten Rollen zurück
     * 
     * @param {IApplicationStateModel} state aktueller State
     * @returns {Role} aktuelle Rollen
     */
    @Selector()
    public static roles(state: IApplicationStateModel): Role {
        return state.roles;
    }
}
