import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {
    APP_INITIALIZER,
    ErrorHandler,
    LOCALE_ID,
    NgModule,
    NgZone,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import {
    MatPaginatorIntl,
    MatPaginatorModule,
} from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import {
    MAT_TOOLTIP_DEFAULT_OPTIONS,
    MatTooltipDefaultOptions,
    MatTooltipModule,
} from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import {
    BrowserSupportModule,
    BrowserSupportService,
    IEngineData,
} from '@ntag-ef/browser-support';
import {
    ErrorHandlerModule,
    NtagErrorHandlerService,
    ON_ERROR,
} from '@ntag-ef/error-handler';
import {
    EnumTranslateLoader,
    EnumTranslationModule,
} from '@ntag-ef/finprocess-enums';
import { NotificationsModule } from '@ntag-ef/notifications';
import { ReleaseNotesModule } from '@ntag-ef/release-notes';
import { NtagServiceWorkerModule } from '@ntag-ef/service-worker';
import { WaiterModule } from '@ntag-ef/waiter';
import { ComponentsModule } from '@ucba/components';
import { DATA_OBSERVABLE, GlobalSettings, SdkModule } from '@ucba/sdk';
import { BaseController } from '@ucba/sdk/controller';
import * as sdkEnums from '@ucba/sdk/enums';
import { ApplicationType } from '@ucba/sdk/enums';
import { IDataType } from '@ucba/sdk/interfaces';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { LoggerModule } from 'ngx-logger';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlankDialogComponent } from './components/blank-dialog/blank-dialog.component';
import { BranchDialogComponent } from './components/branch-dialog/branch-dialog.component';
import { CustomPaginatorComponent } from './components/custom-paginator/custom-paginator.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { SigninSamlComponent } from './components/signin-saml/signin-saml.component';
import { SharedModule } from './modules/shared/shared.module';
import {
    CustomEnumTranslationLoaderService,
    EarlyTranslationLoaderService,
} from './services';
import {
    ApplicationState,
    allStates,
    persistStates,
} from './statemanagement/states';

/**
 * Supported Angular Browsers
 * https://ej2.syncfusion.com/angular/documentation/browser/ *
 */
// const chrome = 63;
// const webkit = 601;
// const safari = 9; //https://en.wikipedia.org/wiki/Safari_version_history
// const firefox = 58;

// ÜBersicht über die Browser und ihre Engine Version
// https://github.com/mdn/browser-compat-data/blob/main/browsers/chrome.json

const engiens: IEngineData[] = [
    {
        name: 'blink',
        displayName: 'Google Chrome',
        downloadLink: 'https://www.google.com/chrome/',
        lastMajorVersion: 110,
    },
    {
        name: 'blink',
        displayName: 'Microsoft Edge',
        downloadLink: 'https://www.microsoft.com/de-de/edge/download',
        lastMajorVersion: 110,
    },
    {
        name: 'webkit',
        displayName: 'Safari',
        downloadLink: 'https://www.apple.com/de/safari/',
        lastMajorVersion: 601,
    },
    {
        name: 'gecko',
        displayName: 'Mozilla Firefox',
        downloadLink: 'https://www.mozilla.org/de/firefox/new/',
        lastMajorVersion: 100,
    },
];

// um LOCALE_ID zu providen
registerLocaleData(localeDe, 'de-AT');

/**
 * Modul Klasse für AppComponent
 */
@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        ImprintComponent,
        LoginComponent,
        RegistrationComponent,
        BranchDialogComponent,
        LogoutComponent,
        SigninSamlComponent,
        BlankDialogComponent,
        CustomPaginatorComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: EarlyTranslationLoaderService,
            },
        }),
        EnumTranslationModule.forRoot({
            loader: {
                provide: EnumTranslateLoader,
                useClass: CustomEnumTranslationLoaderService,
            },
            customEnums: Object.assign({}, sdkEnums),
        }),
        NotificationsModule.forRoot({
            defaultDialogConfig: {
                buttonAlign: 'end',
            },
            defaultToastConfig: {
                verticalPosition: 'top',
            },
        }),
        LoggerModule.forRoot({
            level: environment.logLevel,
            serverLogLevel: environment.serverLogLevel,
            serverLoggingUrl: `${environment.backendUrl}/api/Logging/`,
            serverCallsOutsideNgZone: true,
        }),
        ErrorHandlerModule.forRoot({
            contactPhone: '+49 361 26160 43',
        }),
        WaiterModule.forRoot(),
        NgxsModule.forRoot(allStates, {
            developmentMode: !environment.production,
        }),
        NgxsStoragePluginModule.forRoot({
            key: persistStates,
            storage: StorageOption.SessionStorage,
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production,
        }),
        SdkModule.forRoot({
            backendUrl: environment.backendUrl,
            applicationType: ApplicationType.FINADVISORY,
        }),
        ScrollingModule,
        AppRoutingModule,
        FlexLayoutModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatBadgeModule,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSelectModule,
        MatCardModule,
        MatDialogModule,
        ComponentsModule,
        SharedModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatProgressBarModule,
        NtagServiceWorkerModule.register({
            script: 'ngsw-worker.js',
            serviceWorkerConfig: {
                enabled: environment.checkForUpdateConfiguration.enabled,
                // Register the ServiceWorker as soon as the app is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:30000',
            },
            checkForUpdates: environment.checkForUpdateConfiguration,
        }),
        ReleaseNotesModule,
        BrowserSupportModule,
    ],
    providers: [
        { provide: ErrorHandler, useClass: NtagErrorHandlerService },
        {
            provide: ON_ERROR,
            useFactory: (matDlg: MatDialog, ngZone: NgZone) => () => {
                ngZone.run(() => {
                    matDlg.closeAll();
                });
            },
            deps: [MatDialog, NgZone],
        },
        {
            provide: DATA_OBSERVABLE,
            useFactory: (store: Store) =>
                combineLatest([
                    store.select(ApplicationState.isLoggedIn),
                    store.select(ApplicationState.userId),
                    store.select(ApplicationState.token),
                ]).pipe(
                    map(
                        ([isLoggendIn, userId, token]) =>
                            ({ isLoggendIn, userId, token } as IDataType),
                    ),
                ),
            deps: [Store],
        },
        { provide: LOCALE_ID, useValue: 'de-AT' }, // @see http://www.rfc-editor.org/rfc/bcp/bcp47.txt
        EarlyTranslationLoaderService,
        {
            provide: APP_INITIALIZER,
            useFactory:
                (
                    earlyTranslationLoader: EarlyTranslationLoaderService,
                    enumTranslationLoader: CustomEnumTranslationLoaderService,
                    browserSupportService: BrowserSupportService,
                ) =>
                    // must use full function!!!!
                    async () => {
                        await earlyTranslationLoader.cache();
                        await enumTranslationLoader.cache();
                        await browserSupportService.checkBrowsersEngineCompatibility(engiens);
                    },
            deps: [
                EarlyTranslationLoaderService,
                CustomEnumTranslationLoaderService,
                BrowserSupportService,
            ],
            multi: true,
        },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: {
                showDelay: 800,
                hideDelay: 200,
                touchendHideDelay: 100,
                disableTooltipInteractivity: true,
            } as MatTooltipDefaultOptions,
        },
        { provide: MatPaginatorIntl, useClass: CustomPaginatorComponent },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    /**
     * Standard Konstruktor
     */
    public constructor() {
        BaseController.backendUrl = environment.backendUrl;
        GlobalSettings.debugMode = environment.debug;
    }
}
