import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NtagReleaseNoteService } from '@ntag-ef/release-notes';
import { WaiterService } from '@ntag-ef/waiter';
import { GlobalSettings } from '@ucba/sdk';
import { AccountController, ReleaseNotesController } from '@ucba/sdk/controller';
import { IPGToken, IToken } from '@ucba/sdk/interfaces';
import { HelperService } from '@ucba/sdk/services';
import { Observable, map, mergeMap, of, throwError } from 'rxjs';

import { AuthenticationService } from '../../services';
import { SetToken } from '../../statemanagement/actions';
import { BranchDialogComponent } from '../branch-dialog/branch-dialog.component';


/**
 * Komponente zum Fangen der SAML Response
 */
@Component({
    selector: 'finad-signin-saml',
    templateUrl: './signin-saml.component.html',
    styleUrls: ['./signin-saml.component.scss'],
})
export class SigninSamlComponent implements OnInit {

    /**
     * Standard Konstruktor
     *
     * @param {Router} router Router-Injektor
     * @param {ActivatedRoute} route ActivatedRoute Injektor
     * @param {AuthenticationService} authenticationService AuthenticationService-Injektor
     * @param {WaiterService} waiterService WaiterService-Injektor
     * @param {Store} store Store-Injektor
     * @param {MatDialog} dialog MatDialog-Injektor
     * @param {AccountController} accController AccountController-Injektor
     * @param {NtagReleaseNoteService} ntagReleaseNoteService NtagReleaseNoteService-Injektor
     * @param {ReleaseNotesController} releaseNotesController ReleaseNotesController-Injektor
     */
    public constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private waiterService: WaiterService,
        private store: Store,
        private dialog: MatDialog,
        private accController: AccountController,
        private ntagReleaseNoteService: NtagReleaseNoteService,
        private releaseNotesController: ReleaseNotesController,
    ) { }

    /**
     * Angular Lifecycle-Hook beim Initialisieren der Komponente
     */
    public ngOnInit(): void {
        const jwtToken = this.route.snapshot.queryParams['token'] as string;

        if (!!jwtToken) {
            this.waiterService.show().pipe(
                mergeMap(() => AuthenticationService.externalLogin(jwtToken)),
                mergeMap(userDataParsed => {
                    GlobalSettings.token = jwtToken;
                    return this.checkBranch(userDataParsed);
                }),
                mergeMap(userDataParsed => this.showReleaseNotes(userDataParsed)),
                /* mergemap(usedata =>  this.releasenotes()) */
            ).subscribe(userDataParsed => {
                if (!!userDataParsed) {
                    const localToken: IToken = {
                        token: jwtToken,
                        expire: userDataParsed.exp.toISOString(),
                        created: new Date().toISOString(),
                    };

                    this.authenticationService.setSubscriptions(localToken);

                    // Trigger der Hubs mit neuem Token
                    this.store.dispatch(new SetToken(localToken, userDataParsed.id, userDataParsed.role));
                }

                this.waiterService.hide();
                this.router.navigateByUrl('/').catch(e => { throw e; });

            });
        }
        else {
            this.router.navigateByUrl('/').catch(e => { throw e; });
        }
    }

    /**
     * prüft ob eine Filiale gesetzt ist und öffnet ggf einen Auswahl dialog
     *
     * @param {IPGToken} userDataParsed der erhaltene Token
     * @returns {Observable<IPGToken>} der Token zur weiteren verarbeitung
     */
    private checkBranch(userDataParsed: IPGToken | null): Observable<IPGToken | null> {

        if (!!userDataParsed && HelperService.isNullOrWhitespaces(userDataParsed?.branchId)) {
            const dialogRef = this.dialog.open<BranchDialogComponent, never, string>(BranchDialogComponent, {
                disableClose: true,
            });

            return dialogRef.afterClosed()
                .pipe(
                    mergeMap(branchId => {
                        if (!!branchId) {
                            return this.accController.updateBranchId(branchId)
                                .pipe(map(() => userDataParsed));
                        }
                        else {
                            return throwError(() => new Error('no branch id selected'));
                        }
                    }),
                );
        }
        else {
            return of(userDataParsed);
        }
    }
    /**
     * kommentar
     *
     * @param  {IPGToken|null} userDataParsed userDataParsed
     * @returns {Observable} return
     */
    private showReleaseNotes(userDataParsed: IPGToken | null): Observable<IPGToken | null> {
        if (!!userDataParsed) {
            return this.accController.getEntity().pipe(
                mergeMap(user => this.releaseNotesController.getUserNewestReleaseNotes(user.lastReadReleaseVersion)),
                mergeMap(result => {
                    if (typeof result !== 'boolean') {
                        /* es gibt noch unglese release-notes und wir kriegen ein release-notes container zurück */
                        return this.ntagReleaseNoteService.showReleases(result)
                            .pipe(
                                mergeMap(dialogResult => {
                                    if (dialogResult === 'submit' && Array.isArray(result.releaseVersions) && result.releaseVersions.length > 0) {
                                        return this.releaseNotesController.setUserReadLatestReleaseNote(userDataParsed.id, result.releaseVersions[0].releaseNumber)
                                            .pipe(mergeMap(success => {
                                                if (success) {
                                                    return of(userDataParsed);
                                                }
                                                else {
                                                    return throwError(() => 'error while set releasenotes version');
                                                }
                                            }));
                                    }
                                    else {
                                        return throwError(() => 'releasenotes not excepted');
                                    }
                                }),
                            );
                    }
                    else {
                        return of(userDataParsed); // keine neuen Release-notes
                    }
                }),
            );
        }
        return of(userDataParsed);
    }
}
